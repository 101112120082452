import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory as createHistory } from "history";
import BroadcastIndex from "./components/broadcastPage/BroadcastIndex";

const history = createHistory();

const $ = window.$;
const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => <Route {...rest} render={(props) => <Component {...props} />} isAuthed />;

function App() {
  return (
    <Router history={history}>
      <Switch>
        <AppRoute
          exact
          path={"/:roomId/:id/:token/:live_video_id"}
          component={BroadcastIndex}
        />
      </Switch>
    </Router>
  );
}

export default App;
