import React, { Component } from "react";
import RTCMultiConnection from "rtcmulticonnection";
import DetectRTC from "../../../node_modules/detectrtc/DetectRTC";
import RecordRTC from "../../../node_modules/recordrtc/RecordRTC";
import VideoConnecting from "./VideoConnecting";
import BroadcastHelper from "./BroadcastHelper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import { Redirect } from "react-router-dom";

let connection = new RTCMultiConnection();

// by default, socket.io server is assumed to be deployed on your own URL
// connection.socketURL = "https://rtcmulticonnection.herokuapp.com:443/";

connection.socketURL = "https://cast.blue:9001/";

const $ = window.$;
let chatSocket;

class BroadcastIndex extends BroadcastHelper {
  state = {
    subscribers: [],
    chatSocket: false,
    customizeData: {
      audioMute: true,
      audioUnmute: false,
      videoMute: true,
      videoUnmute: false,
      recordStatus: false,
    },
    snapshot: null,
    startBroadcastInputData: {},
    modelLoadingContent: null,
    modelButtonDisabled: false,
    groupData: null,
    loadingGroup: true,
    liveVideoData: null,
    loadingLiveVideo: true,
    liveVideoSuggesstion: null,
    loadingLiveVideoSuggesstion: true,

    loadMoreButtonDisable: false,
    loadingContent: null,
    skipCount: 0,
    viewerCount: 0,
    streamingStatus: false,
    viewerProfileRedirect: false,
    mainStreamer: false,
    loadingStreamingPage: true,
    apiCallstatus: false,
    startBroadcastButtonDisable: false,
    startBroadcastButtonLoadingContent: null,
    roomId: null,
    id: null,
    token: null,
    live_video_id: null,
    inputIdToken: {},
  };

  componentDidMount() {
    this.oneToManyConfiguration(connection);
    this.setState({
      roomId: this.props.match.params.roomId,
      id: this.props.match.params.id,
      token: this.props.match.params.token,
      live_video_id: this.props.match.params.live_video_id,
    });
    const inputIdToken = { ...this.state.inputIdToken };
    inputIdToken["id"] = this.props.match.params.id;
    inputIdToken["token"] = this.props.match.params.token;
    inputIdToken["live_video_id"] = this.props.match.params.live_video_id;
    this.setState({ inputIdToken });
    this.startStreaming();
  }

  componentDidUpdate(prevProps, prevState) {
    connection.beforeAddingStream = (stream, peer) => {
      console.log("before Streaming called....");
      this.setState({ viewerCount: this.state.viewerCount + 1 });
      return stream;
    };
  }

  nextConnection = () => {
    connection.onstream = (event) => {
      let subscriber = event;
      let subscribers = this.state.subscribers;
      subscribers.push(subscriber);

      this.setState({
        subscribers: subscribers,
        streamingStatus: true,
        loadingStreamingPage: false,
      });

      // to keep room-id in cache
      localStorage.setItem(connection.socketMessageEvent, connection.sessionid);

      if (event.type === "local") {
        connection.socket.on("disconnect", () => {
          if (!connection.getAllParticipants().length) {
            alert(
              "Broadcast is ended. We will reload this page to clear the cache..."
            );
            this.stopBroadcastAPI();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        });
        this.takePhoto(event.mediaElement);
      }
    };

    connection.onEntireSessionClosed = (event) => {
      console.log("Entire session is closed: ", event.sessionid, event.extra);
    };

    connection.onstreamended = (event) => {
      console.log("Connection ended check 1");
      let array = [...this.state.subscribers]; // make a separate copy of the array
      let index = array.indexOf(event);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ subscribers: array });
      }

      if (event.type === "local") {
        if (event.userid === connection.sessionid && !connection.isInitiator) {
          alert(
            "Broadcast is ended. We will reload this page to clear the cache."
          );
          this.stopBroadcastAPI();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else {
        setTimeout(() => {
          window.location.replace("/");
        }, 1000);
      }
    };

    connection.onMediaError = (e) => {
      console.log("Connection ended check 2");
      if (e.message === "Concurrent mic process limit.") {
        if (DetectRTC.audioInputDevices.length <= 1) {
          alert(
            "Please select external microphone. Check github issue number 483."
          );
          return;
        }

        var secondaryMic = DetectRTC.audioInputDevices[1].deviceId;
        connection.mediaConstraints.audio = {
          deviceId: secondaryMic,
        };
        connection.join(connection.sessionid);
      }
    };

    connection.onclose = (event) => {
      console.log("Connection ended check 3");
      alert("data connection closed between you and ");
      window.location.reload();
    };

    connection.onleave = (event) => {
      if (event.type === "remote") {
        alert(" left you.");
        window.location.replace("/");
      }
      if (event.type === "local") {
        alert(" left you.");
        window.location.reload();
      }
    };
  };

  addStreamStopListener = (stream, callback) => {
    console.log("Connection ended check 4");
    stream.addEventListener(
      "ended",
      function () {
        callback();
        callback = function () {};
      },
      false
    );
    stream.addEventListener(
      "inactive",
      function () {
        callback();
        callback = function () {};
      },
      false
    );
    stream.getTracks().forEach(function (track) {
      track.addEventListener(
        "ended",
        function () {
          callback();
          callback = function () {};
        },
        false
      );
      track.addEventListener(
        "inactive",
        function () {
          callback();
          callback = function () {};
        },
        false
      );
    });
  };

  startStreaming = () => {
    const roomid = this.props.match.params.roomId;
    connection.checkPresence(roomid, (isRoomEists, roomid) => {
      if (isRoomEists) {
        connection.sdpConstraints.mandatory = {
          OfferToReceiveAudio: true,
          OfferToReceiveVideo: true,
        };
        connection.join(roomid, (isRoomJoined, roomid, error) => {
          if (error) {
            alert(error);
            window.location.replace("/");
          }
        });
      } else {
        connection.sdpConstraints.mandatory = {
          OfferToReceiveAudio: false,
          OfferToReceiveVideo: false,
        };
        connection.open(roomid);
      }
    });
    this.nextConnection();
  };

  muteVideo = () => {
    let localStream = connection.attachStreams[0];

    let videoTracks = localStream.getVideoTracks();
    if (videoTracks[0]) {
      videoTracks[0].enabled = false;
    }
    // console.log("mutte sdfd", connection.attachStreams);
    // localStream.mute("video");
    const customizeData = { ...this.state.customizeData };
    customizeData["videoMute"] = false;
    customizeData["videoUnmute"] = true;
    this.setState({ customizeData });
    console.log("video muted");
  };
  unmuteVideo = () => {
    let localStream = connection.attachStreams[0];
    let videoTracks = localStream.getVideoTracks();
    if (videoTracks[0]) {
      videoTracks[0].enabled = true;
    }
    // localStream.unmute("video");
    const customizeData = { ...this.state.customizeData };
    customizeData["videoMute"] = true;
    customizeData["videoUnmute"] = false;
    this.setState({ customizeData });
    console.log("video muted");
  };

  muteAudio = () => {
    let localStream = connection.attachStreams[0];
    // localStream
    //   .getTracks()
    //   .forEach((track) => (track.enabled = !track.enabled));

    console.log("localstream data", localStream);

    let audioTracks = localStream.getAudioTracks();
    if (audioTracks[0]) {
      audioTracks[0].enabled = false;
    }

    const customizeData = { ...this.state.customizeData };
    customizeData["audioMute"] = false;
    customizeData["audioUnmute"] = true;
    this.setState({ customizeData });
    console.log("video muted");
  };

  unmuteAudio = () => {
    let localStream = connection.attachStreams[0];
    let audioTracks = localStream.getAudioTracks();
    if (audioTracks[0]) {
      audioTracks[0].enabled = true;
    }

    const customizeData = { ...this.state.customizeData };
    customizeData["audioMute"] = true;
    customizeData["audioUnmute"] = false;
    this.setState({ customizeData });
    console.log("video muted");
  };

  leaveStreaming = (event, inputIndex) => {
    let array = [...this.state.subscribers]; // make a separate copy of the array
    let index = array.indexOf(event);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ subscribers: array });
      let localStream = connection.attachStreams[inputIndex];
      localStream.stop();
    }
  };

  stopStreaming = (event) => {
    // disconnect with all users

    connection.getAllParticipants().forEach(function (pid) {
      connection.disconnectWith(pid);
    });

    // stop all local cameras
    connection.attachStreams.forEach(function (localStream) {
      localStream.stop();
    });

    // close socket.io connection
    connection.closeSocket();
    this.setState({ subscribers: [] });
    this.stopBroadcastAPI();
  };

  takePhoto = (video) => {
    let canvas = document.createElement("canvas");
    canvas.width = video.videoWidth || video.clientWidth;
    canvas.height = video.videoHeight || video.clientHeight;

    let context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    this.setState({ snapshot: canvas.toDataURL("image/png") });
    setTimeout(() => {
      this.snapShotAPI();
    }, 2000);
  };

  snapShotAPI = () => {
    const inputData = {
      snapshot: this.state.snapshot,
      live_video_id: this.state.live_video_id,
    };
    api.postMethod("live_videos_snapshot_save", inputData).then((response) => {
      if (response.data.success) {
        this.setState({
          snapshot: null,
        });
      } else {
      }
    });
  };

  stopBroadcastAPI = () => {
    const inputData = {
      live_video_id: this.state.live_video_id,
    };
    if (this.state.apiCallstatus == false)
      api
        .postMethod("live_videos_broadcast_stop", inputData)
        .then((response) => {
          if (response.data.success) {
            this.setState({ apiCallstatus: true });
          } else {
            // ToastContent(this.props.toastManager, response.data.error, "error");
          }
        });
  };

  eraseOldStreamingAPI = () => {
    api.postMethod("live_videos_erase_old_streamings").then((response) => {
      if (response.data.success) {
        // ToastContent(this.props.toastManager, response.data.message, "success");
        this.startBroadcastAPICall();
      } else {
        // ToastContent(this.props.toastManager, response.data.error, "error");
      }
    });
  };

  viewerUpdateAPI = () => {
    const inputData = {
      live_video_id: this.state.live_video_id,
    };
    api.postMethod("live_videos_viewer_update", inputData).then((response) => {
      if (response.data.success) {
        // ToastContent(
        //     this.props.toastManager,
        //     response.data.message,
        //     "success"
        // );
      } else {
        // ToastContent(this.props.toastManager, response.data.error, "error");
        if (
          response.data.error_code == 169 ||
          response.data.error_code == 170 ||
          response.data.error_code == 171
        ) {
          this.props.history.push("/");
        }
      }
    });
  };

  render() {
    const {
      startBroadcastInputData,
      modelLoadingContent,
      modelButtonDisabled,
      loadingGroup,
      groupData,
      liveVideoSuggesstion,
      loadingLiveVideoSuggesstion,
      loadingContent,
      loadMoreButtonDisable,
      chatData,
      loadingChatData,
    } = this.state;
    return (
      <div class="main">
        {this.state.loadingStreamingPage ? (
          <div className="no-data-found-img">
            <div className="Spacer-10"></div>
            <img
              src={
                window.location.origin +
                "/assets/img/fidget-spinner-loading.gif"
              }
            ></img>
          </div>
        ) : (
          // "Getting details... Please wait.."
          <div className="sec-padding left-spacing1">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 top-margin">
                {this.state.streamingStatus == true &&
                this.state.subscribers.length == 0 ? (
                  <Redirect to="/" />
                ) : null}
                {this.state.subscribers.length > 0 ? (
                  <div className="relative">
                    {this.state.subscribers.map((sub, i) => (
                      <div key={i}>
                        <VideoConnecting
                          streamManager={sub}
                          muteVideo={this.muteVideo}
                          unmuteVideo={this.unmuteVideo}
                          unmuteAudio={this.unmuteAudio}
                          muteAudio={this.muteAudio}
                          stopStreaming={this.stopStreaming}
                          leaveStreaming={this.leaveStreaming}
                          videoIndex={i}
                          customizeData={this.state.customizeData}
                          stopRecordingVideo={this.stopRecordingVideo}
                          takePhoto={this.takePhoto}
                          liveVideoData={this.state.liveVideoData}
                          viewerCount={this.state.viewerCount}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="relative">
                    <img
                      src="/assets/img/stream.jpg"
                      className="img-responsive broadcast-img"
                    />
                    <div className="broadcast-btn">
                      <button
                        className="btn"
                        type="button"
                        // onClick={this.startStreaming}
                        data-toggle="modal"
                        data-target="#start_broadcast"
                        disabled={this.state.startBroadcastButtonDisable}
                      >
                        {this.state.startBroadcastButtonLoadingContent != null
                          ? this.state.startBroadcastButtonLoadingContent
                          : "START BROADCASTING"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withToastManager(BroadcastIndex);
